<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <small class="text-muted">{{ $t('general.basicParameters') }}</small>
          <span class="h6 setting-title">{{ $t('GuestControl.ruleSettings') }}</span>

          <GeneralSettings :redirectRuleData="newRedirectRuleData" :isWizard="true"></GeneralSettings>

          <div class="mt-2">
            <WButton primary outline
              customClass="cpe-register-next-button float-right"
              @click="setNewRedirectRuleData"
              :disabled="isNextButtonDisabled"
            >
              {{ $t('general.next') }}
            </WButton>
            <WButton secondary outline
              customClass="cpe-register-back-button float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralSettings from '../../components/GuestControl/generalSettings';
import commonService from '../../services/commonService';

import { defineRedirectTypeMixin } from '@/views/GuestControl/mixins';


export default {
  components: { GeneralSettings },
  name: 'GuestControlStep1',
  mixins: [defineRedirectTypeMixin],
  data() {
    return {
      newRedirectRuleData: {
        name: '',
        redirect_url: ''
      }
    };
  },
  computed: {
    /**
     * Условия для дизебйла кнопки далее. Зависят от типа редиректа и от наличия ошибок валидации
     * @returns {boolean}
     */
    isNextButtonDisabled() {
      if (this.errors.any()) {
        return true;
      }
      if (this.isStaticRedirectType) {
        if (this.newRedirectRuleData.redirect_url && !this.newRedirectRuleData.url_list.length) {
          return true;
        }
      }
      if (this.isDynamicRedirectType) {
        if (!this.newRedirectRuleData.url_list.length) {
          return true
        }
      }
      return false;
    },
    /**
     * Статический ли тип у редиректа
     *
     * @return {boolean}
     */
    isStaticRedirectType() {
      return this.isStaticRedirectTypeDefine(this.newRedirectRuleData.redirect_type);
    },
    /**
     * Динамический ли тип у редиректа
     *
     * @return {boolean}
     */
    isDynamicRedirectType() {
      return this.isDynamicRedirectTypeDefine(this.newRedirectRuleData.redirect_type);
    },
    commonService() {
      return commonService;
    }
  },
  methods: {
    setNewRedirectRuleData() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.$store.commit('setNewRedirectRuleData', this.newRedirectRuleData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    this.newRedirectRuleData = JSON.parse(JSON.stringify(this.$store.state.newRedirectRuleData));
    // this.newRedirectRuleData.base_location = this.$store.state.userData.base_location;
    // делаем пустым по умолчанию. Задачка WNE-2308
    this.newRedirectRuleData.base_location = '';
  }
};
</script>
<style lang="scss" scoped></style>
