<template>
  <div class="row">
    <div class="col-lg-3">
      <section class="card card-inverse card-warning">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="'/static/img/redirect.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('GuestControl.create') }}</div>
          <div v-if="currentStepIndex === '1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('GuestControl.wizard1Caption') }}</p>
          </div>
          <div v-if="currentStepIndex === '2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('GuestControl.wizard2Caption') }}</p>
          </div>
          <!--<div v-if="currentStepIndex === '3'">-->
          <!--<p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step3') }}</p>-->
          <!--<p>Fill custom profiles</p>-->
          <!--</div>-->
          <!--<div v-if="currentStepIndex === '3'">-->
          <!--<p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step4') }}</p>-->
          <!--<p>Confirm new Redirect Rule creation</p>-->
          <!--</div>-->
        </div>
      </section>
    </div>
    <div class="col-lg-9"><router-view></router-view></div>
  </div>
</template>

<script>
import helpers from "../../helpers";

export default {
  name: 'GuestcontrolWizard',
  computed: {
    currentStepIndex() {
      return this.$route.name.replace('GuestControl-step-', '');
    }
  },
  created() {
    helpers.scrollTopToZero();
    this.$store.commit('clearNewRedirectData');
  },
  methods: {
    nextStep() {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'GuestControl-step-1':
          nextStep = 'GuestControl-step-2';
          break;
        default:
          nextStep = 'GuestControl';
      }
      this.$router.push({ name: nextStep });
      if (nextStep === 'GuestControl') {
        // хак чтобы раздел после создания перерисовывался с нужной локацией в фильтрах
        this.$store.commit('setInHeaderLocationSelectBlockDataChangedTimestamp')
      }
    },
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'GuestControl-step-1':
          prevStep = 'GuestControl';
          if (this.$store.state.stepNameForReturningToCreateWLANWizard) {
            prevStep = 'wlanWizardV2';
          } else if (this.$store.state.returnToEditWlan) {
            prevStep = 'Wlans';
          }
          break;
        case 'GuestControl-step-2':
          prevStep = 'GuestControl-step-1';
          break;
        default:
          prevStep = 'GuestControl';
      }
      this.$router.push({ name: prevStep });
    }
  }
};
</script>
<style lang="scss">
.wizard-wlan-icon {
  height: 80px;
}
.firewall-bg {
  background-color: rgb(189, 189, 189);
}
</style>
