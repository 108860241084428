<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="card">
        <div class="card-block summary">
          <div class="h4 m-0">{{ $t('general.step2') }}</div>
          <small class="text-muted">{{ $t('general.summary') }}</small>
          <p class="mt-1">
            {{ $t('general.name') }}:
            <span class="text-muted">{{ newRedirectRuleData.name }}</span>
          </p>
          <p class="mt-1">
            {{ $t('GuestControl.redirectType') }}:
            <span class="text-muted">{{getRedirectTypeWithi18n(newRedirectRuleData.redirect_type)}}</span>
          </p>
          <p class="mt-1" v-if="areLocationsVisible">
            {{ $t('general.baseLocation') }}:
            <span class="text-muted">{{ commonService.showLocationFromId(newRedirectRuleData.base_location) }}</span>
          </p>
          <p class="" style="word-wrap: break-word" v-if="isStaticRedirectType">
            {{ $t('GuestControl.redirectUrl') }}:
            <span class="text-muted">{{ newRedirectRuleData.redirect_url }}</span>
          </p>
          <div class="mt-q" v-if="newRedirectRuleData.url_list.length" >
            {{ $t('GuestControl.urlList') }}:
            <ul class="list-unstyled">
              <li v-for="url in newRedirectRuleData.url_list" class="text-muted ml-1">
                {{ url.ip }}
                <span v-if="url.domain_name">{{ '—' + url.domain_name }}</span>
              </li>
            </ul>
          </div>
          <div class="mt-2">
            <WButton primary outline
              customClass="cpe-register-next-button float-right"
              @click="confirmRedirectCreation"
            >
              {{ $t('general.confirm') }}
            </WButton>
            <WButton secondary outline
              customClass="cpe-register-back-button float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
import redirectsService from '../../services/redirectsService';
import redirectPollingService from '../../services/redirectPollingService';
import commonService from '../../services/commonService';

import { redirectMixin, defineRedirectTypeMixin } from '@/views/GuestControl/mixins';

export default {
  name: 'GuestControlStep4',
  mixins: [ redirectMixin, defineRedirectTypeMixin ],
  data() {
    return {
      disableButtonWhenRequestExecuting: false,
      newRedirectRuleData: {}
    };
  },
  computed: {
    /**
     * Статический ли тип у редиректа
     *
     * @return {boolean}
     */
    isStaticRedirectType() {
      return this.isStaticRedirectTypeDefine(this.newRedirectRuleData.redirect_type);
    },
    /**
     * Динамический ли тип у редиректа
     *
     * @return {boolean}
     */
    isDynamicRedirectType() {
      return this.isDynamicRedirectTypeDefine(this.newRedirectRuleData.redirect_type);
    },
    commonService() {
      return commonService;
    }
  },
  methods: {
    confirmRedirectCreation() {
      if (this.disableButtonWhenRequestExecuting) {
        return;
      }
      this.disableButtonWhenRequestExecuting = true;
      const correctRedirectRuleData = JSON.parse(JSON.stringify(this.newRedirectRuleData));
      // correctRedirectRuleData.url_list.push({ 'ip': correctRedirectRuleData.redirect_ip, 'domain_name': this.showOnlyDomain(correctRedirectRuleData.redirect_url) });
      // delete correctRedirectRuleData.redirect_ip;
      Vue.axios
        .post(`${API_URL || ''}/api/network/redirects`, { action: 'C', items: { 0: correctRedirectRuleData } })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              redirectPollingService.hadleOperationResponse(response.data.data.items);
              // redirectsService.getRedirectsRule(this);
              redirectsService.getRedirectsRule(this, true);
              if (this.$store.state.stepNameForReturningToCreateWLANWizard) {
                setTimeout(() => {
                  this.$router.push({name: 'wlanWizardV2'})
                  this.disableButtonWhenRequestExecuting = false
                }, 1000);
              } else if (this.$store.state.returnToEditWlan) {
                setTimeout(() => {
                  this.$router.push({name: 'Wlans'})
                }, 1000);
              } else {
                this.disableButtonWhenRequestExecuting = false
                this.$parent.nextStep();
              }
            } else if (response.data.status === 'error') {
              this.disableButtonWhenRequestExecuting = false
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('toggleLoadingFirewalls', true);
            }
          },
          (err) => {
            this.disableButtonWhenRequestExecuting = false
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('toggleLoadingFirewalls', true);
          }
        );
    },
    showOnlyDomain(domain) {
      let correctDomain = domain;
      if (correctDomain.indexOf('https://') !== -1) {
        const domainWOHttp = correctDomain.split('https://');
        correctDomain = domainWOHttp[1];
      } else if (correctDomain.indexOf('http://') !== -1) {
        const domainWOHttp = correctDomain.split('http://');
        correctDomain = domainWOHttp[1];
      }
      if (correctDomain.indexOf('?')) {
        const domainWOQuestion = correctDomain.split('?');
        correctDomain = domainWOQuestion[0];
      }
      if (correctDomain.indexOf('/') !== -1) {
        const domainWOSlash = correctDomain.split('/');
        correctDomain = domainWOSlash[0];
      }
      return correctDomain;
    }
  },
  created() {
    this.newRedirectRuleData = JSON.parse(JSON.stringify(this.$store.state.newRedirectRuleData));
  }
};
</script>
<style lang="scss" scoped></style>
